/* CSS Variables in the form */

#form {
	--timer1: 0.3s;
	--timer2: 0.15s;
	--delay: 1s;
	--flashingQ1: 1.5s;
	min-width: fit-content;
	max-width: 1000px;
}

/* animation on the face selectors */

@keyframes colorChange {
	to {
		/* width: 4.5rem; */
		background: yellow;
		outline: 1px solid #f00;
	}
}

#face5 {
	animation: colorChange var(--timer1) ease var(--delay) 2 alternate,
		colorChange var(--timer1) ease calc(var(--delay) + 13 * var(--timer2)) 2
			alternate;
}
#face4 {
	animation: colorChange var(--timer1) ease
			calc(var(--delay) + 1 * var(--timer2)) 2 alternate,
		colorChange var(--timer1) ease calc(var(--delay) + 12 * var(--timer2)) 2
			alternate;
}
#face3 {
	animation: colorChange var(--timer1) ease
			calc(var(--delay) + 2 * var(--timer2)) 2 alternate,
		colorChange var(--timer1) ease calc(var(--delay) + 11 * var(--timer2)) 2
			alternate;
}
#face2 {
	animation: colorChange var(--timer1) ease
			calc(var(--delay) + 3 * var(--timer2)) 2 alternate,
		colorChange var(--timer1) ease calc(var(--delay) + 10 * var(--timer2)) 2
			alternate;
}
#face1 {
	animation: colorChange var(--timer1) ease
			calc(var(--delay) + 4 * var(--timer2)) 2 alternate,
		colorChange var(--timer1) ease calc(var(--delay) + 9 * var(--timer2)) 2
			alternate;
}

/* Flashing animation on the first quesstion text */
@keyframes flashing {
	50% {
		opacity: 0.5;
	}
}

#Q1 {
	animation: flashing var(--flashingQ1) step-start infinite;
}

/* This doesnt work, I was hoping that if I focus on the input it would stop the flashing of the question */
input[name='visitedBefore']:focus-visible {
	--flashingQ1: 0s;
}

/* IMAGE STYLES */
[type='radio'] + img {
	cursor: pointer;
	width: 4rem;
	text-align: center;
	display: inline-block;
	margin: 1rem;
	padding: 0.2rem;
	border: 2px solid grey;
	border-radius: 10%;
	background: white;
}

h3 {
	text-align: center;
	margin-bottom: 30px;
}

/* CHECKED STYLES */
[type='radio']:checked + img {
	/* animation: sizeChange 0.05s;
	animation-fill-mode: forwards; */
	border: 3px solid #f00;
	/* font-size: 1.5rem; */
	font-weight: bold;
	color: black;
	background: yellow;
	border-radius: 10%;
	width: 5rem;
}

[type='radio'] {
	display: none;
}

a:hover {
	color: red;
}

.grid {
	display: grid;
	grid-template-columns: 1fr 1rem 6fr 1rem 1fr;
	background: white;
	margin-bottom: 150px;
	overflow: hidden;
}

.logos {
	grid-column: 3/4;
	grid-row: 1/2;
	display: flex;
	margin-top: 20px;
	margin-bottom: 40px;
	justify-content: space-between;
}

#form {
	grid-column: 3/-3;
	grid-row: 2/3;
}

#logo1 {
	height: 110px;
}

#logo2 {
	height: 110px;
}

#logo3 {
	height: 110px;
}

@media (max-width: 1250px) {
	#logo1 {
		height: 80px;
	}

	#logo2 {
		height: 80px;
	}
	#logo3 {
		height: 80px;
	}
}

@media (max-width: 1000px) {
	#logo1 {
		height: 65px;
	}

	#logo2 {
		height: 65px;
	}
	#logo3 {
		height: 65px;
	}
	[type='radio'] + img {
		width: 3.5rem;
		margin: 0.8rem;
	}
	[type='radio']:checked + img {
		width: 4.5rem;
	}
}

@media (max-width: 550px) {
	#logo1 {
		height: 46px;
	}

	#logo2 {
		height: 46px;
	}
	#logo3 {
		height: 46px;
	}
	[type='radio'] + img {
		width: 2.5rem;
		margin: 0.5rem;
	}
	[type='radio']:checked + img {
		width: 3rem;
	}
}

@media (max-width: 400px) {
	#logo1 {
		height: 40px;
	}

	#logo2 {
		height: 40px;
	}
	#logo3 {
		height: 40px;
	}
	[type='radio'] + img {
		width: 2.5rem;
		margin: 0.2rem;
	}
	[type='radio']:checked + img {
		width: 3rem;
	}
}

.leftSide {
	grid-column: 1/2;
	grid-row: 1/2;
	background: var(--primary);
}
.rightSide {
	grid-column: 5/6;
	grid-row: 1/2;
	background: var(--primary);
}

.reviewLablesDiv {
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 10px;
	background: hsl(245, 0%, 90%);
	width: 100%;
	height: 30px;
	padding-top: 15px;
	padding-bottom: 12px;
}

h4 {
	text-align: center;
	margin-top: 30px;
	margin-bottom: 30px;
	color: darkslategrey;
}

legend {
	color: hsl(var(--primary-dark));
	font-size: 0.9rem;
	font-family: 'Roboto Slab', serif;
}

#bottom {
	grid-row: 1/4;
	height: 100px;
	width: 100%;
	color: blue;
	margin-bottom: 80px;
}
