body {
	margin: 0;
	box-sizing: border-box;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
		'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

:root {
	--primary: 230;
	--secondary: 30;
	--primary-main: var(--primary), 47%, 55%;
	--primary-light: var(--primary), 60%, 93%;
	--primary-dark: var(--primary), 45%, 30%;
	--secondary-main: var(--secondary), 80%, 50%;
	--secondary-light: var(--secondary), 80%, 70%;
	--secondary-dark: var(--secondary), 80%, 30%;
	--outerWheelDiameter: 50vw;
}

@media (min-width: 700px) {
	:root {
		--outerWheelDiameter: 35vw;
	}
}

@media (min-width: 1000px) {
	:root {
		--outerWheelDiameter: 20vw;
	}
}

@media (min-width: 1500px) {
	:root {
		--outerWheelDiameter: 15vw;
	}
}

#colorWheel {
	background: red;
	margin-top: calc(var(--outerWheelDiameter) * 0.1);
	margin-bottom: calc(var(--outerWheelDiameter) * 0.3);
	height: var(--outerWheelDiameter);
	width: var(--outerWheelDiameter);
	border-radius: 50%;
	position: relative;
	background: transparent;
	background: conic-gradient(
		hsl(0, 60%, 50%),
		hsl(45, 60%, 50%),
		hsl(90, 60%, 50%),
		hsl(135, 60%, 50%),
		hsl(180, 60%, 50%),
		hsl(225, 60%, 50%),
		hsl(270, 60%, 50%),
		hsl(315, 60%, 50%),
		hsl(360, 60%, 50%)
	);
	font-size: calc(var(--outerWheelDiameter) * 0.08);
	font-weight: bold;
}

#innerWheel {
	/* background: hsl(var(--primary-main)); */
	background: hsl(var(--primary-light));
	z-index: 9999;
	left: 50%;
	margin-left: calc(var(--outerWheelDiameter) * -0.25);
	top: 50%;
	margin-top: calc(var(--outerWheelDiameter) * -0.25);
	height: calc(var(--outerWheelDiameter) * 0.5);
	width: calc(var(--outerWheelDiameter) * 0.5);
	border-radius: 50%;
	position: absolute;
}

#colorWheel::before {
	content: '0';
	transform: translateX(calc(var(--outerWheelDiameter) * 0.48))
		translateY(calc(var(--outerWheelDiameter) * -0.12));
	position: absolute;
}

#colorWheel::after {
	content: '180';
	transform: translateX(calc(var(--outerWheelDiameter) * 0.43))
		translateY(calc(var(--outerWheelDiameter) * 1));
	position: absolute;
}

#innerWheel::before {
	content: '90';
	transform: translateX(calc(var(--outerWheelDiameter) * 0.8))
		translateY(calc(var(--outerWheelDiameter) * 0.18));
	position: absolute;
}

#innerWheel::after {
	content: '270';
	transform: translateX(calc(var(--outerWheelDiameter) * -0.42))
		translateY(calc(var(--outerWheelDiameter) * 0.18));
	position: absolute;
}
